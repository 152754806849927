import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TimePickerStatus } from '../../ViewModel/timePickerViewModel/timePickerViewModel';
import {
  TriggeredByOptions,
  WidgetComponents,
  WidgetElements,
} from '../../../../utils/bi/consts';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { AddError } from '../addError/addError';
import settingsParams from '../../settingsParams';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { isMonthDifferent } from '../../../../utils/dateAndTime/dateAndTime';
import { SlotsAvailability } from '../../../../types/types';

export type GoToNextAvailableDate = () => void;

export function createGoToNextAvailableDateAction(
  {
    getControllerState,
    context: { biLogger, calendarApi, settings },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  addError: AddError,
): GoToNextAvailableDate {
  return async () => {
    const [state, setState] = getControllerState();
    const { selectedDate } = state;

    setState({
      timePickerStatus: TimePickerStatus.LOADING,
    });

    const nextAvailableLocalDateTime = await calendarApi.getNextAvailableDate(
      selectedDate!,
      { state, settings, onError: addError },
    );

    if (nextAvailableLocalDateTime) {
      const shouldFetchDateAvailability = isMonthDifferent(
        selectedDate!,
        nextAvailableLocalDateTime,
      );

      await Promise.all([
        setSelectedDate(
          nextAvailableLocalDateTime,
          TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        ),
        shouldFetchDateAvailability
          ? setSelectedMonth(
              nextAvailableLocalDateTime,
              TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
            )
          : Promise.resolve(),
      ]);

      void biLogger.bookingsCalendarClick({
        component: WidgetComponents.TIME_PICKER,
        element: WidgetElements.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        properties: JSON.stringify({
          selectedDate,
          nextAvailableDate: nextAvailableLocalDateTime,
        }),
      });
    } else {
      setState({
        timePickerStatus: TimePickerStatus.NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE,
      });
    }
  };
}
